import React from 'react'
import Button from '@material-ui/core/Button'
import { Link } from 'gatsby'
import EnterCodeInput from '../components/welcome/EnterCodeInput'
import Layout from '../components/layout'
import useGameConfig from '../hooks/useGameConfig'

const Login = () => {
  const { gameConfig: { welcomePage } } = useGameConfig()

  return (
    <Layout pageName="Login">
      <div className="login">
        <h2>Registrieren</h2>
        <h3>{welcomePage.areYouNew}</h3>
        <Button
          component={Link}
          to="/registration"
          variant="contained"
          color="primary"
          fullWidth
        >
          {welcomePage.toRegistration}
        </Button>

        <h2 className="anmelden">Anmelden</h2>
        <h3>{welcomePage.areYouRegistered}</h3>
        <EnterCodeInput/>
      </div>
    </Layout>
  )
}

export default Login
