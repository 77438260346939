import React, { useEffect, useState } from 'react'
import { LinearProgress, TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ForwardIcon from '@material-ui/icons/Forward'
import { GET_MY_TEAM_INVITES_QUERY, GET_PLAYER_BY_CODE_QUERY } from '../../graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import useUser from '../../hooks/useUser'
import { navigate } from 'gatsby'
import useNetwork from '../../hooks/useNetwork'
import useSingleToast from '../../hooks/useSingleToast'
import useGameConfig from '../../hooks/useGameConfig'

const EnterCodeInput = () => {
  const { setRawUser, user } = useUser()
  const [code, setCode] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [logIn, { called, loading, data }] = useLazyQuery(
    GET_PLAYER_BY_CODE_QUERY, {
      fetchPolicy: 'network-only',
      refetchQueries: [
        {
          query: GET_MY_TEAM_INVITES_QUERY,
          variables: {
            code: user?.code
          }
        }
      ]
    }
  )
  const isOnline = useNetwork()
  const { addSingleToast } = useSingleToast()
  const { texts: { notificationTexts } } = useGameConfig()

  const handleSubmit = e => {
    e.preventDefault()

    if (isOnline) {
      logIn({
        variables: {
          clientMutationId: uuidv4(),
          code: code,
        },
        options: {
          onError: e => {
            setErrorMessage(e.message)
          },
        },
      })
    } else {
      addSingleToast(
        <div>
          <p>{notificationTexts.youMustBeOnline}</p>
        </div>,
        { appearance: 'error' })
    }
  }

  useEffect(() => {
    if (!data) return

    if (data?.player === null) {
      setErrorMessage(notificationTexts.codeInvalid)
      return
    }

    if (data.player.databaseId) {
      setRawUser(data, 'synced')
      navigate('/app/overview')
      return null
    }

  }, [data, called])

  if (called && loading) return <LinearProgress/>

  return (
    <form method="post" onSubmit={handleSubmit} color="primary">
      <TextField
        autoComplete="on"
        autoFocus={true}
        label={notificationTexts.playWithCode}
        required={true}
        InputProps={{
          endAdornment: (
            <IconButton type="submit">
              <ForwardIcon/>
            </IconButton>
          ),
        }}
        fullWidth
        onChange={e => {
          setCode(e.target.value)
        }}
        helperText={errorMessage}
        error={errorMessage !== null}
      />
    </form>
  )
}

export default EnterCodeInput
